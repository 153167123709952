import React, { useContext, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';

import AuthContext from '../../store/auth-context';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import Notification from '../MUI/UI/Notification';
import Popup from '../MUI/UI/Popup';
import { useFormatData } from '../Hooks/useFormatData';

import AdditionalChargeRow from '../AdditionalCharges/AdditionalChargeRow';
import AdditionalChargesForm from '../AdditionalCharges/AdditionalChargesForm';
import OrderItemForm from '../OrderItems/OrderItemsForm';

import classes from './../CSS/Orders.module.css';

const OrderDetailsOrderedItems = ({
  recordDetails,
  orderItems,
  additionalCharges,
  userRole,
  setIsLoading,
  setIsError,
  refreshDataHandler,
}) => {
  const authCtx = useContext(AuthContext);

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
    type: 'General',
  });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [openPopupAdditionalChargeForm, setOpenPopupAdditionalChargeForm] = useState(false);
  const [openPopupOrderItemForm, setOpenPopupOrderItemForm] = useState(false);
  const [selectedAdditionalCharge, setSelectedAdditionalCharge] = useState({});
  const [selectedOrderItem, setSelectedOrderItem] = useState({});

  const { FormatCurrencyForDisplay } = useFormatData();

  //
  //  Order Item related code
  //

  const editOrderItemHandler = (uuid) => {
    const orderItem = orderItems.find((record) => record.orderItemUuid === uuid);

    // console.log('##### editOrderItemHandler - uuid = ' + uuid + ' #####');
    // console.log('orderItem');
    // console.log(orderItem);

    setSelectedOrderItem(orderItem);
    setOpenPopupOrderItemForm(true);
  };

  const handleDeleteOrderItem = (uuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteOrderItemData(uuid);
    setNotify({ isOpen: true, message: 'Order Item Successfully Deleted', type: 'success' });
  };

  const deleteOrderItemData = async (uuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'orderitems/' + uuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);
      // console.log('### deleteOrderItemData - OK - ###');
      // setRefreshData(true);
      refreshDataHandler();
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const closeOrderItemFormHandler = () => {
    // console.log('### closeOrderItemFormHandler ###');
    setOpenPopupOrderItemForm(false);
    refreshDataHandler();
  };

  const cancelOrderItemFormHandler = () => {
    setOpenPopupOrderItemForm(false);
  };

  //
  //  Additional Charge related code
  //

  const addAdditionalChargeHandler = () => {
    setSelectedAdditionalCharge({});

    setOpenPopupAdditionalChargeForm(true);
  };

  const editAdditionalChargeHandler = (uuid) => {
    const additionalCharge = additionalCharges.find(
      (record) => record.additionalChargeUuid === uuid
    );

    // console.log('##### editAdditionalChargeHandler - uuid = ' + uuid + ' #####');
    // console.log('additionalCharge');
    // console.log(additionalCharge);

    setSelectedAdditionalCharge(additionalCharge);
    setOpenPopupAdditionalChargeForm(true);
  };

  const handleDeleteAdditionalCharge = (uuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    deleteAdditionalChargeData(uuid);
    setNotify({ isOpen: true, message: 'Additional Charge Successfully Deleted', type: 'success' });
  };

  const deleteAdditionalChargeData = async (uuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'additionalcharges/' + uuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);
      // console.log('### deleteAdditionalChargeData - OK - ###');
      // setRefreshData(true);
      refreshDataHandler();
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const closeAdditionalChargeFormHandler = () => {
    // console.log('### closeAdditionalChargeFormHandler ###');
    setOpenPopupAdditionalChargeForm(false);
    // setRefreshData(true);
    refreshDataHandler();
  };

  const cancelAdditionalChargeFormHandler = () => {
    setOpenPopupAdditionalChargeForm(false);
  };

  return (
    <Box>
      <table className={classes['table-details']}>
        <tbody>
          {orderItems.map((record, index) => (
            <tr key={index}>
              <td className={classes['number']}>{record.quantity}</td>
              <td> x </td>
              <td className={classes['number']}>{FormatCurrencyForDisplay(record.price)}</td>

              {userRole === 'Vendor' &&
              (recordDetails.restaurantStatus === 'New' ||
                recordDetails.restaurantStatus === 'Accepted' ||
                recordDetails.restaurantStatus === 'Modified') ? (
                <>
                  <td className={`${classes['full-width']}`}>
                    {record.name}

                    {record.servingSize && record.priceDetails ? (
                      <div className='small-text pl-5'> (Feeds {record.feeds} - {record.servingSize} - {record.priceDetails})</div>
                    ) : record.servingSize ? (
                      <div className='small-text pl-5'> (Feeds {record.feeds} - {record.servingSize})</div>
                    ) : record.priceDetails ? (
                      <div className='small-text pl-5'> (Feeds {record.feeds} - {record.priceDetails})</div>  
                    ) : (
                      <div className='small-text pl-5'> (Feeds {record.feeds})</div>
                    )}

                    {record.specialInstructions ? (
                      <Box className='details-holder'>
                        <pre>{record.specialInstructions}</pre>
                      </Box>
                    ) : null}
                  </td>

                  {userRole === 'Vendor' &&
                  (recordDetails.restaurantStatus === 'New' ||
                    recordDetails.restaurantStatus === 'Accepted' ||
                    recordDetails.restaurantStatus === 'Modified') ? (
                    <td>
                      <Box sx={{ width: '50px' }}>
                        <IconButton
                          sx={{
                            padding: '0px',
                            margin: '0px',
                          }}
                          onClick={() => editOrderItemHandler(record.orderItemUuid)}
                        >
                          <EditIcon color='primary' />
                        </IconButton>
                        <IconButton
                          sx={{
                            padding: '0px',
                            margin: '0px',
                          }}
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: 'Are you sure you want to delete this order item.',
                              subTitle: 'It will be permanently deleted.',
                              type: 'General',
                              onConfirm: () => {
                                handleDeleteOrderItem(record.orderItemUuid);
                              },
                            });
                          }}
                        >
                          <DeleteForeverIcon color='error' />
                        </IconButton>
                      </Box>
                    </td>
                  ) : null}
                </>
              ) : (
                <td colSpan='2' className={`${classes['full-width']}`}>
                  {record.name}

                  {record.servingSize && record.priceDetails ? (
                    <div className='small-text pl-5'>(Feeds {record.feeds} - {record.servingSize} - {record.priceDetails})</div>
                  ) : record.servingSize ? (
                    <div className='small-text pl-5'>(Feeds {record.feeds} - {record.servingSize})</div>
                  ) : record.priceDetails ? (
                    <div className='small-text pl-5'>(Feeds {record.feeds} - {record.priceDetails})</div>  
                  ) : (
                    <div className='small-text pl-5'>(Feeds {record.feeds})</div>
                  )}

                  {record.specialInstructions ? (
                    <Box className='details-holder'>
                      <pre>{record.specialInstructions}</pre>
                    </Box>
                  ) : null}
                </td>
              )}

              <td className={classes['number']}>{FormatCurrencyForDisplay(record.subtotal)}</td>
            </tr>
          ))}

          {additionalCharges.length > 0 || userRole === 'Vendor' ? (
            <tr>
              <td colSpan='4'>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mb: '5px',
                  }}
                >
                  {additionalCharges.length > 0 ? (
                    <Typography variant='h3' component='h3' sx={{ pt: '20px' }}>
                      Additional Charges
                    </Typography>
                  ) : null}

                  {/* {additionalCharges.length <= 0 ? <div>&nbsp;</div> : null} */}

                  {userRole === 'Vendor' &&
                  (recordDetails.restaurantStatus === 'New' ||
                    recordDetails.restaurantStatus === 'Accepted' ||
                    recordDetails.restaurantStatus === 'Modified') ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Box className='pt-20 pb-10'>
                        <button
                          className='small'
                          onClick={() => {
                            addAdditionalChargeHandler();
                          }}
                        >
                          Add Additional Charge
                        </button>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </td>
            </tr>
          ) : null}

          {additionalCharges.length > 0 ? (
            <>
              {additionalCharges.map((record, index) => (
                <AdditionalChargeRow
                  recordDetails={recordDetails}
                  userRole={userRole}
                  record={record}
                  index={index}
                  key={index}
                  editAdditionalChargeHandler={editAdditionalChargeHandler}
                  setConfirmDialog={setConfirmDialog}
                  handleDeleteAdditionalCharge={handleDeleteAdditionalCharge}
                />
              ))}
            </>
          ) : null}
        </tbody>
      </table>

      <Popup
        openPopup={openPopupOrderItemForm}
        setOpenPopup={setOpenPopupOrderItemForm}
        maxWidth='sm'
        title='Order Item'
      >
        <OrderItemForm
          orderItemDetails={selectedOrderItem}
          onClose={closeOrderItemFormHandler}
          onCancel={cancelOrderItemFormHandler}
        />
      </Popup>

      <Popup
        openPopup={openPopupAdditionalChargeForm}
        setOpenPopup={setOpenPopupAdditionalChargeForm}
        maxWidth='md'
        title='Additional Charge'
      >
        <AdditionalChargesForm
          orderUuid={recordDetails.orderUuid}
          additionalChargeDetails={selectedAdditionalCharge}
          onClose={closeAdditionalChargeFormHandler}
          onCancel={cancelAdditionalChargeFormHandler}
        />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Box>
  );
};

export default OrderDetailsOrderedItems;
