import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import classes from '../CSS/CartItem.module.css';

const CheckoutCartItem = (props) => {
  const { item, onEditCartItem, onDeleteCartItem } = props;
  // console.log('######## CheckoutCartItem ########');
  // console.log('item');
  // console.log(item);

  const editCartItem = () => {
    onEditCartItem(item);
  };

  const deleteCartItem = (event) => {
    event.stopPropagation();
    onDeleteCartItem(item.orderItemUuid);
  };

  const itemTotal = +item.quantity * +item.price;
  const formattedItemTotal = typeof itemTotal === 'number' ? itemTotal.toFixed(2) : itemTotal;
  const displayItemTotal = `$${formattedItemTotal}`;

  const allowEditAndDelete = false;
  const displayServingSize = true;
  const displayFeeds = true;

  const extrainfo = item.servingSize && item.priceDetails
                    ? '(Feeds ' + item.feeds + ' - ' + item.servingSize + ' - ' + item.priceDetails + ')'
                    : item.servingSize
                    ? '(Feeds ' + item.feeds + ' - ' + item.servingSize + ')'
                    : item.priceDetails
                    ? '(Feeds ' + item.feeds + ' - ' + item.priceDetails + ')'
                    : '(Feeds ' + item.feeds + ')';

  return (
    <Box>
      {allowEditAndDelete ? (
        <Box component='div' className={classes['cart-item-clickable']} onClick={editCartItem}>
          <div className={classes['cart-item-header-holder']}>
            <div className={classes['cart-item-quantity-holder']}>{item.quantity} x</div>
            <div className={classes['cart-item-title-and-total-holder']}>
              <Typography variant='label' component='div' className={classes['cart-item-title']}>
                {item.name}
              </Typography>
              <Typography component='span' className={classes['cart-item-total']}>
                {displayItemTotal}
              </Typography>
            </div>
            <div>
              <IconButton onClick={deleteCartItem} sx={{ p: '0px' }}>
                <DeleteForeverIcon color='error' />
              </IconButton>
            </div>
          </div>

          {displayServingSize && item.servingSize ? (
            <Box className={classes['cart-item-serving-size']}>
              <Typography variant='caption' component='span'>
                Serving Size: {item.servingSize}
              </Typography>
            </Box>
          ) : null}

          {displayFeeds && item.feeds && !item.servingSize ? (
            <Box className={classes['cart-item-serving-size']}>
              <Typography variant='caption' component='span'>
                Feeds: {item.feeds}
              </Typography>
            </Box>
          ) : null}

          {item.specialInstructions ? (
            <Box component='div' className={classes['special-instructions']}>
              <Typography variant='h3' component='h3'>
                Meal Selection / Special Instructions
              </Typography>
              <Typography variant='bodyContent' component='p'>
                {item.specialInstructions}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ) : (
        <Box component='div' className={classes['cart-item']}>
          <div className={classes['cart-item-header-holder']}>
            <div className={classes['cart-item-quantity-holder']}>{item.quantity} x</div>
            <div className={classes['cart-item-title-and-total-holder']}>
              <Box>
                <Typography variant='label' component='div' className={classes['cart-item-title']}>
                  {item.name}
                </Typography>

              </Box>
              <Typography component='span' className={classes['cart-item-total']}>
                {displayItemTotal}
              </Typography>
            </div>
          </div>

          <Box align='left' sx={{pl: '50px'}}>
            <Typography component='div' className='small-text' >{extrainfo}</Typography>
          </Box>

          {item.specialInstructions ? (
            <Box component='div' className={classes['special-instructions']}>
              <Typography variant='labelSmall' component='div'>
                Meal Selection / Special Instructions
              </Typography>
              <Typography variant='bodyContentSmall' component='div'>
                {item.specialInstructions}
              </Typography>
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default CheckoutCartItem;
