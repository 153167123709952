import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Collapse, Typography } from '@mui/material';

import {
  displayHelpPhoneOrEmail,
  helpPhoneNumber,
  helpEmailAddress,
  subtotalCartItemsLabel,
} from '../../constants/global';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import * as Constants from '../../helpers/Constants';
import WorkflowMessage from '../UI/WorkflowMessage';
import Notification from '../MUI/UI/Notification';
import Popup from '../MUI/UI/Popup';
import ConfirmationDialog from '../MUI/UI/ConfirmationDialog';
import MenuItemDetails from './MenuItemDetails';
import OrderCartItem from './OrderCartItem';
import OrderDetails from '../Checkout/OrderDetails';
import DeliveryLocationDetails from '../Checkout/DeliveryLocationDetails';
import RestaurantDetails from '../Checkout/RestaurantDetails';
import classes from '../CSS/OrderCart.module.css';

const OrderCart = () => {
  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const orderUuid = orderCtx.orderDetails.orderUuid;
  const orderSubtotal = orderCtx.orderDetails.subtotalFormatted;
  const currentRestaurant = orderCtx.restaurant;
  const dashboardUrl = authCtx.dashboardUrl;
  let baseApiUploadedImagesPath = authCtx.baseApiUploadedImagesPath;

  // console.log('orderUuid = ' + orderUuid);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [openPopup, setOpenPopup] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showOrderDetailsLabel, setShowOrderDetailsLabel] = useState('Show Order Details');
  const [selectedMenuItem, setSelectedMenuItem] = useState({});

  // console.log('orderCtx.items');
  // console.log(orderCtx.items);

  const editCartItemHandler = (item) => {
    // console.log('editCartItemHandler');
    // console.log(item);

    setSelectedMenuItem(item);
    setOpenPopup(true);
  };

  const deleteOrderItemData = async (orderItemUuid) => {
    const response = await fetch(authCtx.baseApiUrl + 'orderitems/' + orderItemUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      // console.log(responseData);
    } else {
      // setIsError(true);
      console.log(' ******** ERROR - deleting an Order Item - ERROR ******** ');
    }
    // setIsLoading(false);
  };

  const deleteCartItemHandler = (orderItemUuid) => {
    deleteOrderItemData(orderItemUuid).catch(console.error);

    orderCtx.deleteOrderItem(orderItemUuid);
  };

  // Don't need this yet.
  // const [additionalCharges, setAdditionalCharges] = useState([]);

  const checkoutHandler = () => {
    // console.log('### checkoutHandler ###');

    // Load order data again to make sure we have the most up to date data for checkout.
    loadOrderData();

    orderCtx.setOrderMode('checkout');
    history.replace('/checkout/delivery-details');
  };

  const loadOrderData = async () => {
    // console.log('######## loadOrderData ########');
    const response = await fetch(authCtx.baseApiUrl + 'orders/' + orderUuid, {
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    });

    // console.log('@@@@@ loadOrderData - response', response);

    if (response.ok) {
      const responseData = await response.json();
      // console.log('@@@@@ loadOrderData - responseData', responseData);

      const recordDetails = responseData.data.orders[0];

      // console.log(' %%%%% calling setOrderDetails %%%%%');
      orderCtx.setOrderDetails(recordDetails);

      // console.log('responseData.data.order_items');
      // console.log(responseData.data.order_items);

      const transformedOrderItemRecords = responseData.data.order_items.map((orderItem) => {
        return {
          orderItemUuid: orderItem.orderItemUuid,
          name: orderItem.name,
          description: orderItem.description,
          details: orderItem.details,
          feeds: orderItem.feeds,
          servingSize: orderItem.servingSize,
          priceDetails: orderItem.priceDetails,
          dietaryOptions: orderItem.dietaryOptions,
          price: orderItem.price,
          quantity: orderItem.quantity,
          menuItemCategory: orderItem.menuItemCategory,
          specialInstructions: orderItem.specialInstructions,
          status: orderItem.status,
          subtotal: orderItem.price * orderItem.quantity,
        };
      });

      // console.log('transformedOrderItemRecords');
      // console.log(transformedOrderItemRecords);

      orderCtx.replaceOrderItems(transformedOrderItemRecords);

      // I don't think we'll ever need this because there are no additional charges as part of the Checkout.
      // const transformedAdditionalChargesRecords = responseData.data.additional_charges.map(
      //   (record) => {
      //     return {
      //       additionalChargeUuid: record.additionalChargeUuid,
      //       name: record.name,
      //       description: record.description,
      //       price: record.price,
      //       quantity: 1,
      //       status: record.status,
      //       subtotal: record.price * 1,
      //     };
      //   }
      // );

      // console.log('transformedAdditionalChargesRecords');
      // console.log(transformedAdditionalChargesRecords);

      // setAdditionalCharges(transformedAdditionalChargesRecords);

      // if (responseData.data.transaction) {
      //   setTransactionData(responseData.data.transaction);
      // }

      if (responseData.data.order_contacts) {
        // console.log('responseData.data.order_contacts');
        // console.table(responseData.data.order_contacts);

        const transformedContacts = responseData.data.order_contacts.map((user) => {
          return {
            userUuid: user.userUuid,
            firstName: user.firstName,
            lastName: user.lastName,
            name: user.firstName + ' ' + user.lastName,
            position: user.position,
            phone: user.phone,
            email: user.email,
            notificationPreference: user.notificationPreference,
            status: user.status,
            roleType: user.roleType,
            orgName: user.orgName ? user.orgName : '',
          };
        });

        // console.log('transformedContacts');
        // console.table(transformedContacts);

        // setOrderContacts(transformedContacts);
        orderCtx.setOrderContacts(transformedContacts);
      }

      if (responseData.data.restaurant) {
        // console.log('responseData.data.restaurant');
        // console.table(responseData.data.restaurant);

        const imageFolder = Constants.IMAGE_FOLDER_PATH;
        const defaultListImage = imageFolder + 'restaurants/default_list_image.jpg';
        const defaultHeaderImage = imageFolder + 'restaurants/default_header_image.jpg';
        // console.log('defaultListImage = ' + defaultListImage);
        // console.log('imageFolder = ' + imageFolder);
        // console.log('defaultHeaderImage = ' + defaultHeaderImage);

        const restaurant = responseData.data.restaurant ? responseData.data.restaurant : null;

        const transformedRestaurantData = {
          restaurantUuid: restaurant.restaurant_uuid,
          name: restaurant.name,
          description: restaurant.description,
          address:
            restaurant.address2 === '' || restaurant.address2 === null
              ? restaurant.address1
              : restaurant.address1 + ', ' + restaurant.address2,
          address1: restaurant.address1,
          address2: restaurant.address2,
          city: restaurant.city,
          state: restaurant.state,
          zipcode: restaurant.zipcode,
          email: restaurant.email,
          phone: restaurant.phone,
          foodType: restaurant.food_type,
          listImage:
            restaurant.listImage === '' || restaurant.listImage === null
              ? defaultListImage
              : baseApiUploadedImagesPath + restaurant.restaurantUuid + '/' + restaurant.listImage,
          // TODO Update this code to use the uploadsFolder.
          headerImage:
            restaurant.header_image === '' || restaurant.header_image === null
              ? defaultHeaderImage
              : baseApiUploadedImagesPath +
                restaurant.restaurant_uuid +
                '/' +
                restaurant.header_image,
          status: restaurant.status,
          leagueExperience: restaurant.league_experience,
          deliveryHours: restaurant.delivery_hours,
          deliveryHoursStart: restaurant.delivery_hours_start,
          deliveryHoursEnd: restaurant.delivery_hours_end,
          orderLeadTime: restaurant.order_lead_time,
          taxRate: restaurant.tax_rate,
          rating: restaurant.rating,
          numberOfRatings: restaurant.number_of_ratings,
          uploadsFolder: restaurant.uploadsFolder,
          isParent: restaurant.isParent,
        };

        // console.log('transformedRestaurantData');
        // console.log(transformedRestaurantData);

        // Don't want to do this until the Continue Order button is clicked.
        // orderCtx.setRestaurant(transformedRestaurantData);
        // setSelectedRestaurant(transformedRestaurantData);
        orderCtx.setRestaurant(transformedRestaurantData);
      }

      if (responseData.data.restaurant_contacts) {
        // console.log('responseData.data.restaurant_contacts');
        // console.table(responseData.data.restaurant_contacts);

        const transformedRestaurantContacts = responseData.data.restaurant_contacts.map((user) => {
          return {
            userUuid: user.userUuid,
            firstName: user.firstName,
            lastName: user.lastName,
            name: user.firstName + ' ' + user.lastName,
            position: user.position,
            phone: user.phone,
            email: user.email,
          };
        });

        // console.log('transformedRestaurantContacts');
        // console.table(transformedRestaurantContacts);

        // setRestaurantContacts(transformedRestaurantContacts);
        orderCtx.setRestaurantContacts(transformedRestaurantContacts);
      }

      if (responseData.data.delivery_location) {
        // console.log('responseData.data.delivery_location');
        // console.table(responseData.data.delivery_location);

        const deliveryLocation = responseData.data.delivery_location
          ? responseData.data.delivery_location
          : {};

        orderCtx.setDeliveryLocation(deliveryLocation);

        // const deliveryLocation = responseData.data.delivery_location;

        // let deliveryLocationAddressLine1 = deliveryLocation.address1
        //   ? deliveryLocation.address1
        //   : '';
        // if (deliveryLocation.address2) {
        //   deliveryLocationAddressLine1 = deliveryLocationAddressLine1.concat(
        //     ' ',
        //     deliveryLocation.address2
        //   );
        // }
        // let deliveryLocationAddressLine2 =
        //   deliveryLocation.city + ', ' + deliveryLocation.state + ', ' + deliveryLocation.zipcode;

        // const deliveryLocationForDisplay = {
        //   name: deliveryLocation.name,
        //   addressLine1: deliveryLocationAddressLine1,
        //   addressLine2: deliveryLocationAddressLine2,
        // };

        // setDeliveryLocationForDisplay(deliveryLocationForDisplay);

        // const returnedLocationDetails = {
        //   deliveryLocationUuid: deliveryLocation.deliveryLocationUuid,
        //   name: deliveryLocation.name,
        //   description: deliveryLocation.description,
        //   address1: deliveryLocation.address1,
        //   address2: deliveryLocation.address2,
        //   city: deliveryLocation.city,
        //   state: deliveryLocation.state,
        //   zipcode: deliveryLocation.zipcode,
        //   email: deliveryLocation.email,
        //   phone: deliveryLocation.phone,
        //   deliveryInstructions: deliveryLocation.deliveryInstructions,
        //   status: deliveryLocation.status,
        // };
        // console.log(' -- returnedLocationDetails -- HERE ');
        // console.log(returnedLocationDetails);

        // orderCtx.setDeliveryLocation(returnedLocationDetails);
      }
    } else {
      console.log(
        '@@@@@@@@@@@@ ERROR getting order data when the checkout button is clicked @@@@@@@@@@@@'
      );
      setIsError(true);
    }

    setIsLoading(false);
  };

  // const deleteOrderHandler = (orderUuid) => {
  //   setConfirmDialog({ ...confirmDialog, isOpen: false });
  //   deleteOrderData(orderUuid);
  //   setNotify({ isOpen: true, message: 'Order Successfully Deleted', type: 'error' });
  // };

  const deleteOrderData = async (orderUuid) => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    const response = await fetch(authCtx.baseApiUrl + 'orders/' + orderUuid, {
      method: 'DELETE',
      headers: {
        Authorization: authCtx.token,
      },
    });

    if (response.ok) {
      // const responseData = await response.json();
      await response.json();
      // console.log(responseData);
      setNotify({ isOpen: true, message: 'Order Successfully Deleted', type: 'success' });

      // history.replace(dashboardUrl + 'new-order');
      history.replace(dashboardUrl + 'orders');
    } else {
      // setIsError(true);
      setNotify({ isOpen: true, message: 'Order Not Successfully Deleted', type: 'error' });
    }
    // setIsLoading(false);
  };

  const cancelOrderHandler = () => {
    setConfirmDialog({
      isOpen: true,
      title: 'Are you sure you want to CANCEL this order?',
      subTitle: 'All Order Data will be deleted.',
      onConfirm: () => {
        // updateSelectedRestuarant(restaurantUuid);
        console.log('cancelOrderHandler - orderUuid = ' + orderUuid);
        deleteOrderData(orderUuid);
      },
    });
  };

  const toggleShowOrderDetails = () => {
    setShowOrderDetails((current) => !current);
    const newLabel =
      showOrderDetailsLabel === 'Show Order Details' ? 'Hide Order Details' : 'Show Order Details';
    setShowOrderDetailsLabel(newLabel);
  };

  const backHandler = () => {
    // console.log('backHandler');
    history.replace('/team-dashboard/new-order');
  };

  const cartItems = orderCtx.items.map((item, index) => (
    <OrderCartItem
      key={index}
      item={item}
      name={item.name}
      onEditCartItem={editCartItemHandler.bind(null, item)}
      onDeleteCartItem={deleteCartItemHandler.bind(null, item.orderItemUuid)}
    />
  ));

  return (
    <Box>
      {isLoading ? <WorkflowMessage type='loading' /> : null}
      {isError ? <WorkflowMessage type='error' /> : null}

      {currentRestaurant.name ? (
        <Box>
          <Box sx={{ pb: '10px' }}>
            <Typography variant='h2' component='h2' align='left'>
              Cart
            </Typography>
          </Box>
          <Box className={classes['cart-items-holder']}>{cartItems}</Box>
          <Box className={classes['total']}>
            <Typography variant='h3' component='span'>
              {subtotalCartItemsLabel}
            </Typography>
            <Typography variant='h3' component='span'>
              {orderSubtotal}
            </Typography>
          </Box>

          <Box className={classes['button-holder']}>
            <Button
              variant='contained'
              color='accent'
              size='custom'
              onClick={checkoutHandler}
              disabled={orderCtx.items.length <= 0}
            >
              Checkout
            </Button>

            <Button variant='contained' color='error' size='custom' onClick={cancelOrderHandler}>
              Cancel Order
            </Button>
          </Box>
        </Box>
      ) : null}

      {/* // TODO: Do this a better way. */}

      {currentRestaurant.name ? (
        <Box sx={{ px: '20px' }}>
          <Box>
            <Button
              variant='text'
              color='accent'
              onClick={toggleShowOrderDetails}
              className='text-button'
              sx={{ px: '0px' }}
            >
              {showOrderDetailsLabel}
            </Button>
          </Box>
          <Collapse in={showOrderDetails}>
            <Box sx={{ display: 'block', pt: '20px' }}>
              <OrderDetails
                orderDetails={orderCtx.orderDetails}
                displayDeliveryAddress={orderCtx.deliveryLocation.city ? false : true}
              />
              <DeliveryLocationDetails deliveryLocation={orderCtx.deliveryLocation} />
              <RestaurantDetails restaurant={orderCtx.restaurant} />
            </Box>
          </Collapse>
        </Box>
      ) : null}

      {!currentRestaurant.name ? (
        <Box sx={{ p: '20px' }}>
          <OrderDetails
            orderDetails={orderCtx.orderDetails}
            displayDeliveryAddress={orderCtx.deliveryLocation.city ? false : true}
          />
          <Box sx={{ pt: '20px' }}>
            <DeliveryLocationDetails deliveryLocation={orderCtx.deliveryLocation} />
          </Box>
          <RestaurantDetails restaurant={orderCtx.restaurant} />

          <Button variant='contained' color='accent' size='small' onClick={backHandler}>
            Back
          </Button>
        </Box>
      ) : null}

      {displayHelpPhoneOrEmail && (helpPhoneNumber || helpEmailAddress) ? (
        <Box component='div' className={classes['cart-info-area']}>
          <Typography variant='h3' component='h3' sx={{ pb: '20px' }}>
            Need Help ?
          </Typography>
          {(displayHelpPhoneOrEmail === 'Phone' || displayHelpPhoneOrEmail === 'Both') &&
          helpPhoneNumber ? (
            <Typography>Call: {helpPhoneNumber}</Typography>
          ) : null}
          {(displayHelpPhoneOrEmail === 'Email' || displayHelpPhoneOrEmail === 'Both') &&
          helpEmailAddress ? (
            <Typography>{helpEmailAddress}</Typography>
          ) : null}
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmationDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
      <Popup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title={selectedMenuItem.name}
        maxWidth='sm'
      >
        <MenuItemDetails
          selectedMenuItem={selectedMenuItem}
          setNotify={setNotify}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
    </Box>
  );
};

export default OrderCart;
