import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Button, Collapse, TextField, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import AuthContext from '../../store/auth-context';
import Notification from '../MUI/UI/Notification';
import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/standard-mui-form.module.css';

const OrderItemForm = (props) => {
  const authCtx = useContext(AuthContext);

  let { orderItemDetails, onClose, onCancel } = props;

  // console.log('##### OrderItemForm ##### orderItemDetails');
  // console.table(orderItemDetails);

  // const [orderItemStatus, setAdditionalChargeStatus] = useState(
  //   orderItemDetails.status ? orderItemDetails.status : 'Active'
  // );
  const [isLoading, setIsLoading] = useState(false);
  // const [isError, setIsError] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: 'success' });
  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);

  const specialInstructionsRef = useRef();
  const quantityRef = useRef();

  let submitButtonText = 'Save';

  const specialInstructions = orderItemDetails.specialInstructions
    ? orderItemDetails.specialInstructions
    : '';

  const setSpecialInstructionsFocus = () => {
    specialInstructionsRef.current.focus();
  };

  const toggleShowSpecialInstructions = () => {
    if (!showSpecialInstructions) {
      setTimeout(setSpecialInstructionsFocus, 200);
    }
    setShowSpecialInstructions((current) => !current);
    specialInstructionsRef.current.focus();
  };

  useEffect(() => {
    if (quantityRef.current) {
      quantityRef.current.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    if (specialInstructions) {
      setShowSpecialInstructions(true);
    }
  }, [specialInstructions, setShowSpecialInstructions]);

  const submitHandler = (event) => {
    event.preventDefault();

    if (orderItemDetails.orderItemUuid === '' || orderItemDetails.orderItemUuid === undefined) {
      setNotify({
        isOpen: true,
        message: 'Order Item can not be saved.',
        type: 'error',
      });

      return;
    }

    setNotify({
      isOpen: true,
      message: 'Sending Request...',
      type: 'info',
    });

    const enteredQuantity = quantityRef.current.value;
    const specialInstructions = specialInstructionsRef.current.value;

    const apiURL = authCtx.baseApiUrl + 'orderitems/' + orderItemDetails.orderItemUuid;
    const apiMethod = 'PATCH';

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        quantity: enteredQuantity,
        special_instructions: specialInstructions,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          quantityRef.current.value = '';

          onClose();
        });
      } else {
        return res.json().then((responseData) => {
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            const messageText = responseData.messages.map((message) => `${message}`).join(', ');
            setNotify({ isOpen: true, message: messageText, type: 'error' });
          } else {
            setNotify({
              isOpen: true,
              message: 'Order Item Not Saved',
              type: 'error',
            });
          }
        });
      }
    });
  };

  const extrainfo = orderItemDetails.servingSize && orderItemDetails.priceDetails
                    ? '(Feeds ' + orderItemDetails.feeds + ' - ' + orderItemDetails.servingSize + ' - ' + orderItemDetails.priceDetails + ')'
                    : orderItemDetails.servingSize
                    ? '(Feeds ' + orderItemDetails.feeds + ' - ' + orderItemDetails.servingSize + ')'
                    : orderItemDetails.priceDetails
                    ? '(Feeds ' + orderItemDetails.feeds + ' - ' + orderItemDetails.priceDetails + ')'
                    : '(Feeds ' + orderItemDetails.feeds + ')';

  return (
    <>
      {isLoading ? (
        <div className='message-holder'>
          <p className='loading'>Loading...</p>
        </div>
      ) : null}

      {!isLoading ? (
        <Box className='small-form'>
          <Box className='mb-20'>
            <Typography variant='label'>{orderItemDetails.name}</Typography>

            <Typography className='pt-5 pl-5 small-text'>
              {extrainfo}
            </Typography>
          </Box>

          <form onSubmit={submitHandler}>
            <Box sx={{ pb: '10px' }}>
              <Box sx={{ pb: '10px' }}>
                <Button
                  variant='text'
                  onClick={toggleShowSpecialInstructions}
                  className='text-button'
                >
                  {showSpecialInstructions ? (
                    <RemoveCircleOutlineIcon sx={{ mr: '10px' }} />
                  ) : (
                    <AddCircleOutlineIcon sx={{ mr: '10px' }} />
                  )}
                  Meal Selection / Special Instructions
                </Button>
              </Box>

              <Collapse in={showSpecialInstructions}>
                <Box>
                  <TextField
                    multiline
                    minRows={3}
                    maxRows={6}
                    fullWidth={true}
                    placeholder='Please enter your meal selections and any other special instructions for this menu item here.'
                    defaultValue={specialInstructions}
                    inputRef={specialInstructionsRef}
                    sx={{ pb: '2rem' }}
                  />
                </Box>
              </Collapse>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2rem',
                justifyContent: 'space-between',
                paddingTop: '2rem',
              }}
            >
              <Box sx={{ width: '200px' }}>
                <FormControls.InputControl
                  type='number'
                  name='quantity'
                  label='Quantity'
                  inputRef={quantityRef}
                  defaultValue={orderItemDetails.quantity ? orderItemDetails.quantity : 1}
                  required
                  inputProps={{ type: 'number', min: '0', max: '100000', step: '1' }}
                  className={classes['mui-control']}
                />
              </Box>

              <Box
                component='div'
                className='button-holder'
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControls.ButtonControl text={submitButtonText} type='submit' color='primary' />
                <FormControls.ButtonControl
                  text='Cancel'
                  onClick={onCancel}
                  type='button'
                  color='neutral200'
                />
              </Box>
            </Box>
          </form>
        </Box>
      ) : null}

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default OrderItemForm;
