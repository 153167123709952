import React from 'react';
import { Box, Paper, Typography } from '@mui/material';

import classes from '../CSS/OrderMenuItemCard.module.css';

const OrderMenuItemCard = (props) => {
  // console.log(' ######## OrderMenuItemCard ######## ');
  const { menuItem, onShowDetails } = props;

  const onMenuItemSelected = () => {
    onShowDetails(menuItem);
  };

  return (
    <Paper
      elevation={12}
      component='div'
      key={menuItem.menuItemUuid}
      className={classes['menu-item-card']}
      onClick={onMenuItemSelected}
    >
      <Box component='div' className={classes['content']}>
        {menuItem.image ? (
          <Box className={classes['menu-item-image-holder']}>
            <img src={menuItem.image} alt={menuItem.name} />
          </Box>
        ) : null}

        <Typography variant='h7' component='h5'>
          {menuItem.name}
        </Typography>

        {menuItem.description ? (
          <Typography variant='bodyContent' component='p'>
            {menuItem.description}
          </Typography>
        ) : null}

        <Box>
          <Typography className={classes['price']} sx={{ display: 'inline-block' }}>
            {menuItem.priceFormatted}
          </Typography>

          <Typography variant='caption' component='span' sx={{ pl: '10px' }}>
            (
              {menuItem.feeds ? 'Feeds ' + menuItem.feeds : null}
              {menuItem.servingSize ? ' - ' + menuItem.servingSize : null}
              {menuItem.priceDetails ? ' - ' + menuItem.priceDetails : null}
            )
          </Typography>
         
        </Box>
        <Typography>{menuItem.dietaryOptions}</Typography>
      </Box>
    </Paper>
  );
};

export default OrderMenuItemCard;
