import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Collapse, IconButton, TextField, Typography } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { selectServingSizeLabel } from '../../constants/global';

import FormControls from '../MUI/FormControls/FormControls';
import classes from '../CSS/MenuItemForm.module.css';

const MenuItemForm = (props) => {
  const {
    orderItemUuid,
    selectedMenuItem,
    menuItems,
    setNotify,
    onChangePrice,
    onAddToCart,
    onUpdateCart,
    onDeleteItem,
  } = props;

  // console.log('######## MenuItemForm ########');
  // console.log('menuItems');
  // console.log(menuItems);

  // console.log('ORDER - selectedMenuItem');
  // console.log(selectedMenuItem);

  // Get the Child Menu Items if there are any.
  let childMenuItems = [];
  if (menuItems) {
    childMenuItems = menuItems
      .filter(
        (menuItem) =>
          menuItem.menuItemUuid === selectedMenuItem.menuItemUuid ||
          menuItem.parentUuid === selectedMenuItem.menuItemUuid
      )
      .map((menuItem) => {
        return {
          menuItemUuid: menuItem.menuItemUuid,
          parentUuid: menuItem.parentUuid,
          restaurantUuid: menuItem.restaurantUuid,
          name: menuItem.name,
          description: menuItem.description,
          details: menuItem.details,
          servingSize: menuItem.servingSize,
          feeds: menuItem.feeds,
          price: menuItem.price,
          priceFormatted: menuItem.priceFormatted,
          priceDetails: menuItem.priceDetails,
          category: menuItem.menuItemCategory,
          dietaryOptions: menuItem.dietaryOptions,
          status: menuItem.status,
        };
      });
  }

  // console.log('childMenuItems');
  // console.log(childMenuItems);

  const servingSizeItems = childMenuItems.map((menuItem) => {
    return {
      value: menuItem.menuItemUuid,
      label: menuItem.priceFormatted,
      extrainfo: menuItem.servingSize && menuItem.priceDetails 
        ? '(Feeds ' + menuItem.feeds + ' - ' + menuItem.servingSize + ' - ' + menuItem.priceDetails + ')' 
        : menuItem.servingSize
        ? '(Feeds ' + menuItem.feeds + ' - ' + menuItem.servingSize + ')' 
        : menuItem.priceDetails
        ? '(Feeds ' + menuItem.feeds + ' - ' + menuItem.priceDetails + ')'
        : '(Feeds ' + menuItem.feeds + ')',
    };
  });

  const [showSpecialInstructions, setShowSpecialInstructions] = useState(false);
  const [selectedServingSizeMenuItemUuid, setSelectedServingSizeMenuItemUuid] = useState(
    selectedMenuItem.menuItemUuid
  );

  const quantityRef = useRef();
  const specialInstructionsRef = useRef();

  const setSpecialInstructionsFocus = () => {
    specialInstructionsRef.current.focus();
  };

  const toggleShowSpecialInstructions = () => {
    if (!showSpecialInstructions) {
      setTimeout(setSpecialInstructionsFocus, 200);
    }
    setShowSpecialInstructions((current) => !current);
    specialInstructionsRef.current.focus();
  };

  // console.log('selectedMenuItem');
  // console.log(selectedMenuItem);

  const quantityOrdered = selectedMenuItem.quantity ? selectedMenuItem.quantity : 1;
  const specialInstructions = selectedMenuItem.specialInstructions
    ? selectedMenuItem.specialInstructions
    : '';
  const buttonText = orderItemUuid ? 'Update Item' : 'Add to Cart';

  useEffect(() => {
    if (specialInstructions) {
      setShowSpecialInstructions(true);
    }
  }, [specialInstructions, setShowSpecialInstructions]);

  const deleteCartItem = () => {
    // console.log(' deleteCartItem ');
    // console.log(' orderItemUuid = ' + orderItemUuid);
    onDeleteItem(orderItemUuid);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredQuantity = quantityRef.current.value;
    const enteredQuantityNumber = +enteredQuantity;
    const specialInstructions = specialInstructionsRef.current.value;

    if (
      enteredQuantity.trim().length === 0 ||
      enteredQuantityNumber < 1 ||
      enteredQuantityNumber > 10000
    ) {
      // setFormIsValid(false);
      setNotify({
        isOpen: true,
        message: 'Please enter a valid Quantity (1 - 10,000)',
        type: 'warning',
      });
      return;
    }
    // props.setNotify({
    //   isOpen: true,
    //   message: 'Quantity entered = ' + enteredQuantity,
    //   type: 'success',
    // });
    // console.log(' - enteredQuantity = ' + enteredQuantity);

    // console.log(' - MenuItemForm - submitHandler - orderItemUuid = ' + orderItemUuid + ' - ');

    if (orderItemUuid) {
      onUpdateCart(selectedServingSizeMenuItemUuid, enteredQuantityNumber, specialInstructions);
    } else {
      onAddToCart(selectedServingSizeMenuItemUuid, enteredQuantityNumber, specialInstructions);
    }
  };

  const getSelectedServingSizePrice = (menuItemUuid) => {
    // console.log('getSelectedServingSizePrice - menuItemUuid = ' + menuItemUuid);
    const selectedMenuItem = childMenuItems.filter(
      (menuItem) => menuItem.menuItemUuid === menuItemUuid
    );
    // console.log('selectedMenuItem');
    // console.table(selectedMenuItem);

    const selectedServingSizePrice = selectedMenuItem[0].priceFormatted;
    const selectedServingSizePriceDetails = selectedMenuItem[0].priceDetails;

    // console.log('selectedServingSizePrice = ' + selectedServingSizePrice);

    onChangePrice(selectedServingSizePrice, selectedServingSizePriceDetails);
  };

  const servingSizeChangeHandler = (event) => {
    // console.log('** servingSizeChangeHandler - event.target.value = ' + event.target.value);
    setSelectedServingSizeMenuItemUuid(event.target.value);
    getSelectedServingSizePrice(event.target.value);
  };

  return (
    <Box component='form' onSubmit={submitHandler} className={classes['add-to-cart-form']}>
      {/* <Typography>orderItemUuid = {orderItemUuid}</Typography> */}
      {/* <Typography>quantityOrdered = {quantityOrdered}</Typography> */}
      {/* <Typography>specialInstructions = {specialInstructions}</Typography> */}


      <Box>
        {servingSizeItems.length > 1 ? (
          <Box>
            <FormControls.RadioGroupControl
              name='serving_size'
              label={selectServingSizeLabel}
              value={selectedServingSizeMenuItemUuid}
              onChange={servingSizeChangeHandler}
              items={servingSizeItems}
              direction='column'
            />
          </Box>
        ) : null}
      </Box>

      <Box sx={{ pb: 2 }}>
        <Button variant='text' onClick={toggleShowSpecialInstructions} className='text-button'>
          {showSpecialInstructions ? (
            <RemoveCircleOutlineIcon sx={{ mr: '10px' }} />
          ) : (
            <AddCircleOutlineIcon sx={{ mr: '10px' }} />
          )}
          Meal Selection / Special Instructions
        </Button>
      </Box>
      <Collapse in={showSpecialInstructions}>
        <TextField
          multiline
          minRows={3}
          maxRows={6}
          fullWidth={true}
          placeholder='Please enter your meal selections and any other special instructions for this menu item here.'
          defaultValue={specialInstructions}
          inputRef={specialInstructionsRef}
          sx={{ pb: '2rem' }}
        />
      </Collapse>

      <Box component='div' className={classes['button-holder']}>
        <Box>
          <Typography variant='bold'>Enter Quantity</Typography>
          <TextField
            id={selectedMenuItem.menuItemUuid}
            size='small'
            defaultValue={quantityOrdered}
            type='number'
            min='1'
            max='10000'
            step='1'
            sx={{ width: '10rem' }}
            inputRef={quantityRef}
          />
        </Box>

        <Box>
          {orderItemUuid ? (
            <IconButton aria-label='delete' onClick={deleteCartItem}>
              <DeleteForeverIcon color='error' />
            </IconButton>
          ) : null}

          <Button
            variant='contained'
            color='accent'
            size='custom'
            type='submit'
            className={classes['button']}
          >
            {/* <AddIcon className={classes['button-icon']} /> */}
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuItemForm;
