import React, { useContext, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

import AuthContext from '../../store/auth-context';
import OrderContext from '../../store/order-context';
import MenuItemForm from './MenuItemForm';
import classes from '../CSS/MenuItemDetails.module.css';

const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

const MenuItemDetails = (props) => {
  const { selectedMenuItem, menuItems, setNotify, setOpenPopup } = props;

  const authCtx = useContext(AuthContext);
  const orderCtx = useContext(OrderContext);

  const orderItemUuid = selectedMenuItem.orderItemUuid ? selectedMenuItem.orderItemUuid : '';

  const [servingSizePrice, setServingSizePrice] = useState(
    selectedMenuItem.priceFormatted
      ? selectedMenuItem.priceFormatted
      : selectedMenuItem.price
      ? priceFormatter.format(selectedMenuItem.price)
      : '$0.00'
  );

  const [servingSizePriceDetails, setServingSizePriceDetails] = useState(
    selectedMenuItem.priceDetails ? selectedMenuItem.priceDetails : ''
  );

  const displayServingSizePriceDetails = false;

  const extrainfo = selectedMenuItem.servingSize && selectedMenuItem.priceDetails
    ? '(Feeds ' + selectedMenuItem.feeds + ' - ' + selectedMenuItem.servingSize + ' - ' + selectedMenuItem.priceDetails + ')'
    : selectedMenuItem.servingSize
    ? '(Feeds ' + selectedMenuItem.feeds + ' - ' + selectedMenuItem.servingSize + ')'
    : selectedMenuItem.priceDetails
    ? '(Feeds ' + selectedMenuItem.feeds + ' - ' + selectedMenuItem.priceDetails + ')'
    : '(Feeds ' + selectedMenuItem.feeds + ')';

  const saveOrderItemData = (menuItemUuid, quantity, specialInstructions) => {
    let apiURL = '';
    let apiMethod = '';

    if (orderItemUuid !== '') {
      apiURL = authCtx.baseApiUrl + 'orderitems/' + orderItemUuid;
      apiMethod = 'PATCH';
    } else {
      apiURL = authCtx.baseApiUrl + 'orderitems';
      apiMethod = 'POST';
    }

    fetch(apiURL, {
      method: apiMethod,
      body: JSON.stringify({
        order_uuid: orderCtx.orderDetails.orderUuid,
        order_item_uuid: orderItemUuid,
        menu_item_uuid: menuItemUuid,
        quantity: quantity,
        special_instructions: specialInstructions,
      }),
      headers: {
        Authorization: authCtx.token,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      // setIsLoading(false);

      if (res.ok) {
        return res.json().then((responseData) => {
          // console.log(' ** SUCCESS ** ');
          // console.log(responseData);

          const orderItem = responseData.data.order_items[0];
          // const orderItemUuid = responseData.data.order_items[0].orderItemUuid;

          // console.log(' **** orderItemUuid = ' + orderItemUuid + ' **** ');

          if (apiMethod === 'POST') {
            orderCtx.addOrderItem(orderItem);
          } else {
            orderCtx.updateOrderItem(orderItem);
          }

          setOpenPopup(false);
        });
      } else {
        return res.json().then((responseData) => {
          // Todo: Show an Error Modal.
          console.log(' ** ERROR ** ');
          console.log(responseData);
          if (
            responseData &&
            Array.isArray(responseData.messages) &&
            responseData.messages.length
          ) {
            // setDisplayMessage(responseData.messages);
          } else {
            // setDisplayMessage(['Failed to save Order Data.']);
          }
        });
      }
    });
  };

  const changePriceHandler = (price, priceDetails) => {
    // console.log('changePriceHandler = price = ' + price + ' - priceDetails = ' + priceDetails);
    setServingSizePrice(price);
    setServingSizePriceDetails(priceDetails);
  };

  const addToCartHandler = (menuItemUuid, quantity, specialInstructions) => {
    // Save order_items data to the DB.
    saveOrderItemData(menuItemUuid, quantity, specialInstructions);
  };

  const updateItemInCartHandler = (menuItemUuid, quantity, specialInstructions) => {
    saveOrderItemData(menuItemUuid, quantity, specialInstructions);
  };

  const deleteItemFromCartHandler = (orderItemUuid) => {
    // console.log('deleteItemFromCartHandler');
    // console.log('orderItemUuid = ' + orderItemUuid);

    const deleteData = async () => {
      const response = await fetch(authCtx.baseApiUrl + 'orderitems/' + orderItemUuid, {
        method: 'DELETE',
        headers: {
          Authorization: authCtx.token,
        },
      });

      if (response.ok) {
        // const responseData = await response.json();
        // console.log(responseData);
        // Todo:  Show a notification.
      } else {
        // setIsError(true);
        console.log(' ******** ERROR - deleting an Order Item - ERROR ******** ');
      }
      // setIsLoading(false);
    };

    deleteData().catch(console.error);

    orderCtx.deleteOrderItem(orderItemUuid);
    setOpenPopup(false);
  };

  return (
    <div className={classes['menu-item-details']}>
      {selectedMenuItem.image ? (
        <Box className={classes['menu-item-image-holder']}>
          <Tooltip title='Click to display fullsize image.'>
            <a href={selectedMenuItem.image} target='_blank' rel='noreferrer'>
              <img
                src={selectedMenuItem.image}
                alt={selectedMenuItem.name}
                className={classes['menu-item-image']}
              />
            </a>
          </Tooltip>
        </Box>
      ) : null}
      {/* <Typography variant='h7' component='h5'>
        {selectedMenuItem.name}
      </Typography> */}

      <Box className='flex-container-side-by-side-small-gap'>
        <Typography className={classes['price']} sx={{ display: 'inline-block' }}>
          {servingSizePrice}
        </Typography>

        {extrainfo ? (
          <Typography component='span' className='small-text pt-5'>
            {extrainfo}
          </Typography>
        ) : null}
      </Box>

      {displayServingSizePriceDetails && servingSizePriceDetails ? (
        <Typography variant='caption' component='p' sx={{ display: 'inline-block', pl: '2rem' }}>
          {servingSizePriceDetails}
        </Typography>
      ) : null}

      {selectedMenuItem.dietaryOptions ? (
        <Typography variant='caption' component='p' align='right' sx={{ pb: '1rem' }}>
          {selectedMenuItem.dietaryOptions}
        </Typography>
      ) : null}

      {selectedMenuItem.description ? (
        <Box className='pt-10'>
          <Typography variant='labelAbove'>Description</Typography>
          <Typography variant='bodyContent' component='pre'>
            {selectedMenuItem.description}
          </Typography>
        </Box>
      ) : null}
      {selectedMenuItem.details ? (
        <Box sx={{ pt: '1rem' }}>
          <Typography variant='labelAbove'>Details</Typography>
          <Typography variant='bodyContent' component='pre'>
            {selectedMenuItem.details}
          </Typography>
        </Box>
      ) : null}

      <div className={classes['form-holder']}>
        <MenuItemForm
          orderItemUuid={orderItemUuid}
          selectedMenuItem={selectedMenuItem}
          menuItems={menuItems}
          setNotify={setNotify}
          onChangePrice={changePriceHandler}
          onAddToCart={addToCartHandler}
          onUpdateCart={updateItemInCartHandler}
          onDeleteItem={deleteItemFromCartHandler}
        />
      </div>
    </div>
  );
};

export default MenuItemDetails;
