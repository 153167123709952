import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import classes from './CartItem.module.css';

const CartItem = (props) => {
  const { item, onAdd, onRemove } = props;

  const price = +item.price;
  let formattedPrice = typeof price === 'number' ? price.toFixed(2) : price;
  formattedPrice = `$${formattedPrice}`;

  const extrainfo = item.servingSize && item.priceDetails 
                    ? '(Feeds ' + item.feeds + ' - ' + item.servingSize + ' - ' + item.priceDetails + ')' 
                    : item.servingSize
                    ? '(Feeds ' + item.feeds + ' - ' + item.servingSize + ')' 
                    : item.priceDetails
                    ? '(Feeds ' + item.feeds + ' - ' + item.priceDetails + ')'
                    : '(Feeds ' + item.feeds + ')';

  return (
    <Box component='div' className={classes['cart-item']}>
      <Typography variant='h3' component='h2'>
        {item.name}

        {extrainfo ? (
          <Typography className='small-text pt-5'>
            {extrainfo}
          </Typography>
        ) : null}
      </Typography>

      <Box component='div' className={classes['details']}>
        <Box component='div' className={classes['summary']}>
          <Typography className={classes['price']}>{formattedPrice}</Typography>
          <Typography variant='body1' component='span' className={classes['quantity']}>
            x {item.quantity}
          </Typography>
        </Box>
        <Box component='div' className={classes['actions']}>
          <Button variant='outlined' color='primary' size='small' onClick={onRemove}>
            <RemoveIcon />
          </Button>
          <Button variant='outlined' color='primary' size='small' onClick={onAdd}>
            <AddIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CartItem;
