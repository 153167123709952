import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import classes from '../CSS/CartItem.module.css';

const OrderCartItem = (props) => {
  const { item, onEditCartItem, onDeleteCartItem } = props;

  const displayExtraInfoExtraLine = true;

  const editCartItem = () => {
    onEditCartItem(item);
  };

  const deleteCartItem = (event) => {
    event.stopPropagation();
    onDeleteCartItem(item.orderItemUuid);
  };

  const itemTotal = +item.quantity * +item.price;
  const formattedItemTotal = typeof itemTotal === 'number' ? itemTotal.toFixed(2) : itemTotal;
  const displayItemTotal = `$${formattedItemTotal}`;

  const extrainfo = item.servingSize && item.priceDetails
                    ? '(Feeds ' + item.feeds + ' - ' + item.servingSize + ' - ' + item.priceDetails + ')'
                    : item.servingSize
                    ? '(Feeds ' + item.feeds + ' - ' + item.servingSize + ')'
                    : item.priceDetails
                    ? '(Feeds ' + item.feeds + ' - ' + item.priceDetails + ')'
                    : '(Feeds ' + item.feeds + ')';

  return (
    <div>
      <Box className={classes['cart-item-clickable']} onClick={editCartItem}>

        <Box className={classes['cart-item-header-holder']}>
          <Box className={classes['cart-item-quantity-holder']}>{item.quantity} x</Box>
          <Box className={classes['cart-item-title-and-total-holder']}>
            <Box>
              <Typography variant='label' component='div' className={classes['cart-item-title']}>
                {item.name}
              </Typography>

              {!displayExtraInfoExtraLine ? (
                <Typography component='div' className='small-text'>
                  {extrainfo}
                </Typography>
              ) : null}

            </Box>
            <Typography component='span' className={classes['cart-item-total']}>
              {displayItemTotal}
            </Typography>
          </Box>
          <Box className={classes['cart-item-delete-button-holder']}>
            <IconButton onClick={deleteCartItem} sx={{ p: '0px' }}>
              <DeleteForeverIcon color='error' />
            </IconButton>
          </Box>
        </Box>

        {displayExtraInfoExtraLine ? (<Typography component='div' className='small-text' sx={{pl: '50px'}}>{extrainfo}</Typography>) : null}

        {item.specialInstructions ? (
          <Box className={classes['special-instructions']}>
            <Typography variant='labelSmall' component='div'>
              Meal Selection / Special Instructions
            </Typography>
            <Typography variant='bodyContentSmall' component='div'>
              {item.specialInstructions}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </div>
  );
};

export default OrderCartItem;
