const apiVersion = 'v2';
const apiLocation = 'SD';

const buildVersion = 'v3.9.3';
const buildVersionDate = '2024-12-22';

const apiEnvironment = 'production';
// const apiEnvironment = 'live-api-sd';
// const apiEnvironment = 'sd-live';

// const apiEnvironment = 'staging';
// const apiEnvironment = 'sd-staging';

// const apiEnvironment = 'development';

// const apiEnvironment = 'local';

let environmentTitle = '';
let environmentTitleFooter = '';
//
switch (apiEnvironment) {
  case 'development':
    environmentTitle = 'Development';
    break;
  case 'staging':
    environmentTitle = 'Staging';
    environmentTitleFooter = 'Staging - api-sd';
    break;
  case 'sd-live':
    environmentTitle = 'SD-Live';
    break;
  case 'sd-staging':
    environmentTitle = 'SD-Staging';
    break;
  default:
    environmentTitle = '';
}

if (apiEnvironment === 'live-api-sd' || (apiEnvironment === 'production' && apiLocation === 'SD')) {
  environmentTitleFooter = 'api-sd';
} else {
  environmentTitleFooter = environmentTitle;
}

const displayHelpPhoneOrEmail = 'Email';
const helpPhoneNumber = '1-847-235-0141';
const helpEmailAddress = 'support@eliteeatsinc.com';

const displayTeamCharge = true;
const teamChargeRate = 5; // teamChargeRate is a %
const teamChargeLabel = 'Payment Processing Fee';
const deliveryFeeLabel = 'Delivery & Service Fee';
const deliveryFeesLabel = 'Delivery & Service Fees';
const subtotalCartItemsLabel = 'Subtotal (Cart Items)';
const subtotalLabel = 'Subtotal';
const eliteEatsPayoutMainLabel = 'Elite Eats Payout';
const eliteEatsPayoutSubLabel = '(5% Stripe payment processing & 15% commission)';
const servingSizeLabel = 'Option'; // Serving Size
const selectServingSizeLabel = 'Select Option';
const servingSizeLabelForForm = 'Option (pricing option)';
const priceDetailsLabel = 'Packaging'; // Price Details
const priceDetailsLabelForForm = 'Packaging';

// There are in set in 2 places.
// I need to clean that up.
const displayTaxInfo = false;
const defaultMenuItemImage = 'default_menu_item.jpg';
const displayAddNewLocationButton = false;
const defaultOrderLeadTime = 12; // 24; // - WAS - 72;

// This value is used in the API as well so this should only be changed if changed in both places.
const paymentNotConfirmedStatusValue = 'Payment Not Confirmed';
const orderLeadTimeExceededStatusValue = 'Order Lead Time Exceeded';

const noRestaurantsExplanationLine1 =
  'Restaurants have an Order Lead Time that is at least ' + defaultOrderLeadTime + ' Hours.';
const noRestaurantsExplanationLine2 =
  'Please make sure that you have selected a Delivery Date and Time accordingly.';

export {
  apiEnvironment,
  environmentTitle,
  environmentTitleFooter,
  apiVersion,
  apiLocation,
  buildVersion,
  buildVersionDate,
  displayHelpPhoneOrEmail,
  helpPhoneNumber,
  helpEmailAddress,
  displayTeamCharge,
  teamChargeRate,
  teamChargeLabel,
  deliveryFeeLabel,
  deliveryFeesLabel,
  subtotalCartItemsLabel,
  subtotalLabel,
  eliteEatsPayoutMainLabel,
  eliteEatsPayoutSubLabel,
  servingSizeLabel,
  selectServingSizeLabel,
  servingSizeLabelForForm,
  priceDetailsLabel,
  priceDetailsLabelForForm,
  displayTaxInfo,
  defaultMenuItemImage,
  displayAddNewLocationButton,
  defaultOrderLeadTime,
  paymentNotConfirmedStatusValue,
  orderLeadTimeExceededStatusValue,
  noRestaurantsExplanationLine1,
  noRestaurantsExplanationLine2,
};
